import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import API_URL from "../utils/config";
import ReactMarkdown from "react-markdown";
import { Select, MenuItem } from "@mui/material";
import akirabrand from "../static/akira-ai-brand-logo.svg";
import sendicon from "../static/send-button.svg";
import chathistoryicon from "../static/search-history-icon.svg";
import updatesicon from "../static/updates-and-faq-icon.svg";
import darkmodeicon from "../static/dark-mode-icon.svg";
import accessibilityicon from "../static/accessibility-icon.svg";
import { ThreeDots } from "react-loader-spinner";
import Header from "./Header";
import "./style.css";
import "../common/grid-new.css";
import "../common/primary-new.css";

function HomePage() {
  const [isTyping, setIsTyping] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  const messageContainerRef = useRef(null);
  const [userQuestion, setUserQuestion] = useState("");
  const [conversation, setConversation] = useState([]);
  const [queryHistory, setQueryHistory] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCopilotSectionHidden, setIsCopilotSectionHidden] = useState(false);
  const [isChatMessageSectionHidden, setIsChatMessagetSectionHidden] =
    useState(true);
  const [dropdownData, setDropdownData] = useState([]);
  const [selectedDropdownItem, setSelectedDropdownItem] = useState("");
  const [selectedIndexForUpload, setSelectedIndexForUpload] = useState("");
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(`${API_URL}/list_objects`);
      if (response.status === 200) {
        setDropdownData(response.data);
        if (!selectedDropdownItem && response.data.length > 0) {
          setSelectedDropdownItem(response.data[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const scrollToLatestMessage = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToLatestMessage();
  }, [conversation]);

  const startNewConversation = () => {
    setConversation([]);
    setIsCopilotSectionHidden(true); // Set isCopilotSectionHidden to true
    const initialMessage = `Hi! How may I assist you?`;
    setConversation([{ text: initialMessage, type: "bot" }]);
  };

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);
  }, []);

  useEffect(() => {
    const newSessionGenerated = sessionStorage.getItem("newSessionGenerated");
    if (!newSessionGenerated) {
      const restoreChatHistory = sessionStorage.getItem("chatHistory");
      const restoreQuery = sessionStorage.getItem("queries");
      if (restoreChatHistory) {
        setConversation(JSON.parse(restoreChatHistory));
      }
      if (restoreQuery) {
        setQueryHistory(JSON.parse(restoreQuery));
      }
    } else {
      sessionStorage.setItem("chatHistory", JSON.stringify(conversation));
      sessionStorage.setItem("queries", JSON.stringify(queryHistory));
      sessionStorage.setItem("newSessionGenerated", "false");
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("chatHistory", JSON.stringify(conversation));
    sessionStorage.setItem("queries", JSON.stringify(queryHistory));
  }, [conversation, queryHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsCopilotSectionHidden(true); // Set isCopilotSectionHidden to true
    setIsChatMessagetSectionHidden(false);

    const newConversation = [...conversation];
    if (userQuestion) {
      newConversation.push({ text: userQuestion, type: "user" });
    }
    setConversation(newConversation);

    try {
      if (userQuestion) {
        setLoading(true);
        const modifiedurl =
          selectedIndexForUpload || selectedDropdownItem.replace(/\//g, "");
        const response = await axios.post(
          `${API_URL}/query?index_name=${modifiedurl}`,
          {
            dataframe_split: {
              columns: ["query"],
              data: [[userQuestion]],
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          const data = response.data;
          if (data && data.predictions && data.predictions.length > 0) {
            const botAnswer = data.predictions[0];
            const words = botAnswer.split(" ");
            const botMessage = {
              text: botAnswer,
              type: "bot",
            };
            const displayAnswer = async () => {
              setIsTyping(true);
              const words = botAnswer.split(" ");
              let fullAnswer = "";
              for (const word of words) {
                fullAnswer += word + " ";
                const newBotMessage = {
                  text: fullAnswer.trim(),
                  type: "bot",
                };
                setConversation((prevConversation) => {
                  const updatedConversation = [...prevConversation];
                  updatedConversation[prevConversation.length - 1] =
                    newBotMessage;
                  return updatedConversation;
                });
                scrollToBottom();
                await new Promise((resolve) => setTimeout(resolve, 50));
              }
              setIsTyping(false);
            };
            newConversation.push(botMessage);
            displayAnswer(botAnswer);
          } else {
            console.error(
              "API response is missing the expected data structure."
            );
          }
        } else {
          console.error("Error fetching data from API:", response.status);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }

    setQueryHistory([...queryHistory, userQuestion]);
    setUserQuestion("");
    setConversation(newConversation);
    scrollToLatestMessage();
  };

  const typewriterEffect = (element) => {
    const text = element.innerText;
    element.innerText = "";

    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (charIndex < text.length) {
        const currentChar = text.charAt(charIndex);
        if (currentChar === " ") {
          element.innerHTML += "&nbsp;";
        } else {
          element.innerText += currentChar;
        }
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 70);
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages");
    chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  const handleFileUpload = async (files) => {
    const fileLabel = document.getElementById("file-label");
    if (files && files.length > 0) {
      const file = files[0];
      const formData = new FormData();
      formData.append("file", file);
      const modifiedurl = file.name.replace(/\//g, "");
      setSelectedIndexForUpload(modifiedurl);
      try {
        const response = await axios.post(
          `${API_URL}/upload_document?index_name=${modifiedurl}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          setFileUploadSuccess(true);
          fileLabel.textContent = "Upload File";
          // Do not add success message to conversation
          scrollToLatestMessage();
        } else {
          console.error("Error uploading file:", response.status);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      fileLabel.textContent = "Upload File";
    }
  };
  
  
  

  return (
    <div className="sec-chatbot-wrapper">
      <div className="app-wrapper">
        <div
          className={`hamburger-menu ${isSidebarOpen ? "open" : ""}`}
          onClick={toggleSidebar}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-3">
            <div
              className={`sidenav ${
                isSidebarOpen ? "sidenavigation-open" : ""
              }`}
            >
              <div className="button-wrapper">
                <div className="upper-button-wrapper">
                  <div className="custom-dropdown-wrapper">
                    <h2>Select Document Here</h2>
                    <select
                      value={selectedDropdownItem}
                      onChange={(e) => setSelectedDropdownItem(e.target.value)}
                    >
                      <option value="">Select an option</option>
                      {dropdownData.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="upload-wrapper">
                    <input
                      type="file"
                      className="custom-upload-input"
                      onChange={(e) => handleFileUpload(e.target.files)}
                    />
                    <div className="upload-button-heading">
                      <h2>Or, Upload your document here</h2>
                    </div>
                    <label className="custom-upload-label" id="file-label">
                      Upload File
                    </label>
                    {fileUploadSuccess && (
                      <p className="file-upload-success">
                        File uploaded successfully
                      </p>
                    )}
                  </div>
                </div>
                <div className="new-complaint-button">
                  <button id="new-conversation" onClick={startNewConversation}>
                    New Conversation
                  </button>
                </div>
                <div className="search-history-wrapper">
                  {queryHistory.map((query, index) => (
                    <div className="query-history-items-wrapper" key={index}>
                      <div
                        className="query-history-item"
                        onClick={() => setUserQuestion(query)}
                      >
                        <img
                          src={chathistoryicon}
                          alt="Query-history-icon"
                          id="query-history-icon"
                        />
                        <p style={{ whiteSpace: "nowrap" }}>
                          {query.split(" ").slice(0, 6).join(" ")}
                          {query.split(" ").length > 4 ? "..." : ""}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sidenav-services-wrapper">
                <ul>
                  <li>
                    <img
                      src={accessibilityicon}
                      alt=""
                      className="sidenav-icon"
                    />
                    <a href="#">Accessibility</a>
                  </li>
                  <li>
                    <img src={darkmodeicon} alt="" className="sidenav-icon" />
                    <a href="#">Dark Mode</a>
                  </li>
                  <li>
                    <img src={updatesicon} alt="" className="sidenav-icon" />
                    <a href="#">Updates and FAQ</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9">
            <div
              className={`chatbot-content ${
                isSidebarOpen ? "chatbot-content-hidden" : ""
              }`}
            >
              <Header />
              {!isCopilotSectionHidden && (
                <div className="copilot-section">
                  <div className="container prompt-container-wrapper">
                    <section className="prompts-part">
                      <div className="bot-image">
                        <img
                          src={akirabrand}
                          alt="akira-ai-brand-logo"
                          id="akira-brand-icon"
                        />
                      </div>
                      <div className="assistant-heading">
                        <h2>
                          <span className="bold-text">InsightsGA</span> -
                          Generative Agent for Banking
                        </h2>
                      </div>
                      <div className="row suggested-prompt-row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Disputed Transaction</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What to do in case of dispute regarding delayed credit to the customer’s account from ADWM?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Accident Claim</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              Give me details on Motor Accident Claim Annuity Deposit.
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Saving Account Features</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What are the eligibility criteria for customer to open Insta Plus Saving Account?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Unsuccessful Transactions</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What to do if customer has a dispute related to unsuccessful transaction?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Document Inquiries</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What are the documents required as Proof of Status in Banks?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Policy Inquiries</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              Give me info on the nomination facility of banks?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Account Closure</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What are the steps for closure of Accounts?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Account Type Query</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What are the different types of deposit accounts that the customer can open?
                            </p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-4 prompt-column-wrapper">
                          <div className="prompts-card">
                            <h3>Address Proof</h3>
                            <p
                              onClick={(e) =>
                                setUserQuestion(e.target.innerText)
                              }
                            >
                              What documents are required as a proof of permanent address?
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              )}
              {!isChatMessageSectionHidden && (
                <div className="chat-messages" ref={messageContainerRef}>
                  {conversation.map((message, index) => (
                    <div
                      className={`messages-align ${
                        message.type === "bot" ? "bot" : "user"
                      }`}
                      key={index}
                    >
                      <div className="avatar">
                        {message.type === "user" ? (
                          <p></p>
                        ) : (
                          <div className="response">
                            <img
                              src={akirabrand}
                              alt="Bot Avatar"
                              className="bot-avatar"
                            />
                          </div>
                        )}
                      </div>
                      <div
                        className={`message ${
                          message.type === "user" ? "user" : "bot"
                        }`}
                      >
                        <div className="message-content">
                          {message.type === "bot" &&
                            message.text.includes("Suggested Questions:") && (
                              <>
                                <p>
                                  {message.text
                                    .split("Suggested Questions:")[0]
                                    .trim()}
                                  <br />
                                  <br />
                                  Suggested Questions:
                                </p>
                                <div className="suggested-questions">
                                  {message.text
                                    .split("Suggested Questions:")[1]
                                    .split("\n")
                                    .map((question, idx) => (
                                      <p
                                        key={idx}
                                        onClick={() =>
                                          setUserQuestion(
                                            question
                                              .replace(/^\d+\./, "")
                                              .trim()
                                          )
                                        }
                                      >
                                        {question.replace(/^\d+\./, "").trim()}
                                      </p>
                                    ))}
                                </div>
                              </>
                            )}
                          {message.type === "bot" &&
                            !message.text.includes("Suggested Questions:") && (
                              <p>{message.text}</p>
                            )}
                          {message.type === "user" && (
                            <div className="message user">
                              <p>{message.text}</p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <form onSubmit={handleSubmit} className="chat-input">
                <input
                  type="text"
                  placeholder="Enter your Queries"
                  value={userQuestion}
                  onChange={(e) => setUserQuestion(e.target.value)}
                />
                {loading ? (
                  <ThreeDots
                    height="80"
                    width="60"
                    radius="9"
                    color="#2d6ada"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                ) : (
                  <img
                    src={sendicon}
                    onClick={handleSubmit}
                    alt="send-icon"
                    id="send-icon"
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
