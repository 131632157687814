import React from "react";
import headericonone from "../static/bank-analysis-logo.svg";
import headericontwo from "../static/akira-ai-logo-image.svg";
import "./style.css";
import "../common/grid-new.css";
import "../common/primary-new.css";

function Header(){
    return(
        <header className="header">
        <div className="container">
          <div class="navbar">
            <a href="#" className="dubai-govt-logo">
              <img
                src={headericonone}
                alt="us-exchange-logo"
                id="header-icon-one"
              />
            </a>
            <a href="#" className="header-icon-two">
              <img src={headericontwo} alt="akira-ai-logo" id="header-icon-two" />
            </a>
          </div>
        </div>
      </header>
    )
}
export default Header;